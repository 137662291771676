import React, { useState } from 'react';

// Components
import Modal from '..//modal';
import ConnectForm from '../connect-form';
import Button from "../button/button";
import ButtonModal from '../button-modal/button-modal';
import ButtonExternalSource from '../button-external-source/button-external-source';

// Hooks
import useRichText from "../../hooks/api/useRichText";

function ColorBgWithTwoPictures({
  idx,
  backgroundColor,
  accentColor,
  title,
  description,
  firstImage,
  secondImage,
  buttonTitle,
  newTab,
  buttonLink,
  showImages,
  tail
}) {
  const { parseBodyText } = useRichText();
  const [showModal, setShowModal] = useState(false);

  return (
    <section className={`${idx === 0 ? 'pt-48 sm:pt-72 lg:pt-44' : 'pt-20 sm:pt-32'} pb-20 sm:pb-24 px-5 sm:px-7 lg:px-20`} style={{ backgroundColor: `${backgroundColor}` }}>
      <h6 className={`relative w-full lg:w-3/4 text-5xl sm:text-8xl lg:text-9xl xl:text-13xl pb-6 lg:pb-12 font-bold font-comp uppercase`} style={{color: accentColor}}>
        {title}
        <span className={'absolute bottom-0 left-0.5 w-11 sm:w-93px lg:w-52 h-1 sm:h-5px lg:h-2.5'} style={{ backgroundColor: accentColor }}></span>
      </h6>
      <div className={`flex flex-wrap ${showImages ? 'justify-center lg:justify-between mt-12 sm:mt-20' : 'justify-end'} lg:mt-24`}>
        {showImages && (
          <ul className={'flex flex-col w-full sm:w-4/6 md:w-3/4 lg:w-2/6 lg:pt-11'}>
            <li className={'self-start'}><img className={'w-44 xs:w-56 sm:w-342px lg:w-60 h-44 xs:h-56 sm:h-342px lg:h-60 border-none rounded-full object-center bg-white'} src={firstImage.fields.file.url} alt={firstImage.fields.title} /></li>
            <li className={'self-end'}><img className={'w-32 xs:w-40 sm:w-60 lg:w-44 h-32 xs:h-40 sm:h-60 lg:h-44 -mt-12 lg:mt-0 border-none rounded-full object-center bg-white'} src={secondImage.fields.file.url} alt={firstImage.fields.title} /></li>
          </ul>
        )}
        <div className={`${tail ? 'resource' : ''} mt-9 sm:mt-12 lg:w-6/12 lg:mt-0`}>
          <div className={`${showImages ? 'text-lg sm:text-28px lg:text-2xl' : 'text-lg sm:text-28px lg:text-lg leading-tight'} font-sans text-base-color`}>
            {parseBodyText(description)}
          </div>
            <div className={'flex justify-between w-full'}>
              {
                (buttonTitle && buttonLink && buttonLink.includes("http"))  ? 
                
                  <ButtonExternalSource
                    classnames={'px-11'}
                    url={buttonLink}
                    color={accentColor}
                    text={buttonTitle}
                    newTab={newTab} />

                : 
                (buttonTitle === 'Connect with us' || buttonTitle === 'Subscribe') ?

                  <ButtonModal
                    color={accentColor}
                    text={buttonTitle}
                    setShowModal={setShowModal} />

                : 
                (buttonTitle && buttonLink) ?

                  <Button
                    url={buttonLink}
                    color={accentColor}
                    text={buttonTitle}
                    newTab={newTab} />

                : ''
              }
            </div>
        </div>
      </div>
      <Modal showModal={showModal}>
          <ConnectForm setShowModal={setShowModal} />
      </Modal>
    </section>
  )
}

export default ColorBgWithTwoPictures;
