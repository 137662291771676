import React from 'react';

function ButtonModal({ color, text, setShowModal }) {
  return (
    <div onClick={e => setShowModal(true)} className={'group hover:top-1.5 hover:left-1.5 relative px-8 sm:px-11 py-2 sm:py-4 bg-white text-lg sm:text-2xl font-bold font-comp uppercase tracking-wider mt-12 border-3 text-center sm:min-w-17 cursor-pointer'} style={{ borderColor: `${color}`, color: `${color}`, boxShadow: `3px 3px ${color}` }}>
      <span className={'group-hover:w-0.5 group-hover:-right-5px group-hover:-top-2px w-1.5 h-calc-border absolute top-0 -right-9px transform skew-y-45'} style={{ backgroundColor: `${color}` }}></span>
      {text}
      <span className={'group-hover:h-0.5 group-hover:-bottom-5px group-hover:-left-2px w-calc-border h-1.5 absolute -bottom-9px left-0.2px rounded-br-sm transform skew-x-45'} style={{ backgroundColor: `${color}` }}></span>
    </div >
  );
}

export default ButtonModal;
