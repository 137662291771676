import React from 'react';

// Hooks
import useHome from "../../hooks/ui/useHome";

// components
import ColorBgWithTwoPictures from "../../components/color-bg-with-two-pictures";
import QuoteWithTexts from "../../components/quote-with-texts";

function HomeView() {
  const { sections } = useHome();
  
  return (
    <main>
      {sections.map((item, idx) => {
        if (item.fields && item.sys.contentType.sys.id === 'componentColorBackgroundTitleAndTwoPictures') {
          const { accentColor, description, title, backgroundColor, buttonTitle, buttonLink, newTab, firstImage, secondImage, showImages } = item.fields;
          return (
            <ColorBgWithTwoPictures
              key={idx}
              idx={idx}
              title={title}
              accentColor={accentColor}
              description={description}
              backgroundColor={backgroundColor}
              buttonTitle={buttonTitle}
              newTab={newTab}
              buttonLink={buttonLink}
              firstImage={firstImage}
              secondImage={secondImage}
              showImages={showImages} />
          )
        } else if (item.fields && item.sys.contentType.sys.id === "componentQuoteWithTexts") {
          const { accentColor, description, title, backgroundColor, buttonTitle, image, quoteText } = item.fields;
          return (
            <QuoteWithTexts
              key={idx}
              title={title}
              accentColor={accentColor}
              description={description}
              backgroundColor={backgroundColor}
              buttonTitle={buttonTitle}
              image={image}
              quoteText={quoteText}/>
          )
        } else return null
      })}
    </main>
  );
}

export default HomeView;
