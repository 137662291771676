import useContentful from '../api/useContentful';

export default function useHome() {
    const { data, isLoading } = useContentful('3GuFznD7oI2zVaYY8d3LnO');
    const sections  = data?.sections || [];
    
    return {
        sections,
        isLoading
    };
}
