import React from 'react';
import useRichText from "../../hooks/api/useRichText";
import QuoteIcon from "../quoteIcon/quoteIcon";

function QuoteWithTexts({backgroundColor, accentColor, title, description, quoteText}) {
  const { parseBodyText } = useRichText();

    return (
      <section className={'relative pt-20 sm:pt-32 pb-16 sm:pb-28 px-5 sm:px-7 lg:px-28'} style={{ backgroundColor: `${backgroundColor}` }}>
        <div className={'flex flex-wrap justify-between'}>
          <div className={'hidden lg:block w-5/12'}>
            <QuoteIcon color={accentColor}/>
            <div style={{color: accentColor}}>
              {parseBodyText(quoteText)}
            </div>
          </div>
          <div className={'quote block lg:hidden absolute top-60 sm:top-355px lg:top-0 lg:static lg:w-5/12 order-2 lg:order-none'}>
            <QuoteIcon color={accentColor}/>
            <div className={'quote-text'} style={{color: accentColor}}>
              {parseBodyText(quoteText)}
            </div>
          </div>
          <div className={'lg:w-5/12 order-1 lg:order-none'}>
            <h6 className={'relative pb-6 lg:pb-0 w-1/2 lg:w-auto text-5xl sm:text-7xl lg:text-8xlg font-comp font-bold uppercase text-black'} style={{ color: accentColor }}>
              {title}
              <span className={'block lg:hidden absolute bottom-0 left-0.5 w-93px lg:w-52 h-5px lg:h-2.5 bg-black'}></span>
            </h6>
            <div className={'mt-52 sm:mt-64 lg:mt-14'}>
              <div className={'text-lg sm:text-28px lg:text-2xl font-sans text-base-color'}>
                {parseBodyText(description)}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default QuoteWithTexts;
